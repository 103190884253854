<template>
  <div>
    <div>
      <b-card
        title="รายการเล่นเรียงตามเวลา"
      >
        <b-table
          :ref="`bet_txn_timeseries`"
          striped
          hover
          responsive
          class="position-relative"
          :per-page="betDetailTable.page_size"
          :items="betDetailTable.items"
          :fields="betDetailTable.fields"
        >
          <template #cell(username)="data">
            {{ data.item.phoneno }}<br>
            {{ data.item.username }}
          </template>
          <template #cell(sa_betdetails)="data">
            <div v-html="format_sa_betdetail(data.item, 'detail')" />
          </template>
          <template #cell(sa_result)="data">
            <div v-html="format_sa_betdetail(data.item, 'result')" />
          </template>
          <template #cell(sbo_betdetail)="data">
            <div v-html="format_sbo_betdetail(data.item, 'subbet')" />
          </template>
          <template #cell(sbo_result)="data">
            <div v-html="format_sbo_betdetail(data.item, 'detail')" />
          </template>
          <template #cell(game_id)="data">
            <template v-if="data.item.betdata && data.item.provider == 'WM'">
              {{ data.item.betdata.game_type }}<br>
              {{ data.item.betdata.gameid }}<br>
              {{ data.item.betdata.hostid }}
            </template>
            <template v-if="data.item.betdata && data.item.provider == 'PG'">
              {{ data.item.betdata.game_id }}<br>
              {{ data.item.betdata.parent_bet_id }}
            </template>
            <template v-if="data.item.betdata && data.item.provider == 'AE'">
              {{ data.item.betdata.game_type }}<br>
              {{ data.item.betdata.host_id }}<br>
              {{ data.item.betdata.game_id }}
            </template>
          </template>
          <template #cell(payout_time)="data">
            <template v-if="data.item.betdata && data.item.provider == 'SA'">
              {{ formatDate(data.item.betdata.settle_time) }}
            </template>
            <template v-if="data.item.betdata && (data.item.provider == 'WM' || data.item.provider == 'JOKER')">
              {{ formatDate(data.item.betdata.payout_datetime) }}
            </template>
            <template v-if="data.item.betdata && data.item.provider == 'AE'">
              {{ formatDate(data.item.betdata.payout_time) }}
            </template>
            <template v-if="data.item.betdata && data.item.provider == 'DG'">
              {{ formatDate(data.item.betdata.settle_time) }}
            </template>
          </template>
          <template #cell(betdetails)="data">
            <template v-if="data.item.provider == 'WM'">
              {{ ((data.item.betdata || {}).betdetails || {}).betResult }}
            </template>
            <template v-if="data.item.betdata && data.item.provider == 'SA'">
              <div v-html="format_sa_bettype(((data.item.betdata || {}).betdetails || []).details)" />
            </template>
            <template v-if="data.item.betdata && data.item.provider == 'AE'">
              {{ data.item.betdata.bet_type }}
            </template>
            <template v-if="data.item.provider == 'WALLET'">
              <div v-html="data.item.note" />
            </template>
            <template v-if="data.item.betdata && data.item.provider == 'SBO'">
              <div v-html="format_sbo_betdetail(data.item.betdata, 'subbet')" />
            </template>
          </template>
          <template #cell(result)="data">
            <template v-if="data.item.betdata && data.item.provider == 'WM'">
              {{ ((data.item.betdata || {}).betdetails || {}).gameResult }}
            </template>
            <template v-if="data.item.betdata && data.item.provider == 'AE' && data.item.betdata.game_type == 'SEXYBCRTLIVE'">
              <div v-html="format_sexy_result(data.item.betdata.payload.result, data.item.betdata.payload.winner)" />
            </template>
            <template v-if="data.item.betdata && data.item.provider == 'AE' && data.item.betdata.game_type == 'KINGMAKERTABLE' && data.item.betdata.payload.link">
              <a
                :id="`view-slip-${data.item.bet_id}`"
                @click="viewResult(data.item.betdata.payload.link, 'iframe')"
              >ดูผลเกมส์</a>
            </template>
            <template v-if="data.item.betdata && data.item.provider == 'JOKER'">
              <a
                :id="`view-slip-${data.item.bet_id}`"
                @click="viewResult(data.item.betdata.result_url, 'iframe')"
              >ดูผลเกมส์</a>
            </template>
            <template v-if="data.item.betdata && data.item.provider == 'PG'">
              <a
                :id="`view-slip-${data.item.bet_id}`"
                @click="viewResult(data.item.betdata.result_url, 'iframe')"
              >ดูผลเกมส์</a>
            </template>
            <template v-if="data.item.betdata && data.item.provider == 'SA'">
              <div v-html="format_sa_betresult(data.item.betdata, 'detail')" />
            </template>
            <template v-if="data.item.betdata && data.item.provider == 'SBO'">
              <div v-html="format_sbo_betdetail(data.item.betdata, 'detail')" />
            </template>
          </template>
          <template #cell(result_amount)="data">
            <template v-if="data.item.betdata && (data.item.provider == 'WM')">
              <div :class="(data.item.betdata || {}).result_amount > 0 ? 'text-success':'text-danger'">{{ numberWithCommas((data.item.betdata || {}).result_amount) }}</div>
            </template>
            <template v-if="data.item.betdata && (data.item.provider == 'JOKER')">
              <div :class="(data.item.betdata || {}).result_amount - Math.abs(data.item.bet_amount) > 0 ? 'text-success':'text-danger'">{{ numberWithCommas((data.item.betdata || {}).result_amount - Math.abs(data.item.bet_amount)) }}</div>
            </template>
            <template v-if="data.item.betdata && (data.item.provider == 'SA')">
              <div :class="data.item.betdata.gross_win > 0 ? 'text-success':'text-danger'">{{ numberWithCommas(data.item.betdata.gross_win) }}</div>
            </template>
            <template v-if="data.item.betdata && data.item.provider == 'PG'">
              <div :class="data.item.betdata.settle_amount - data.item.betdata.bet_amount > 0 ? 'text-success':'text-danger'">{{ numberWithCommas(data.item.betdata.settle_amount - data.item.betdata.bet_amount) }}</div>
            </template>
            <template v-if="data.item.betdata && (data.item.provider == 'AE')">
              <div :class="data.item.betdata.result_amount > 0 ? 'text-success':'text-danger'">{{ numberWithCommas(data.item.betdata.result_amount) }}</div>
            </template>
            <template v-if="data.item.betdata && (data.item.provider == 'SBO' && data.item.betdata.detail)">
              <div :class="data.item.betdata.detail.winLost > 0 ? 'text-success':'text-danger'">{{ numberWithCommas(data.item.betdata.detail.winLost) }}</div>
            </template>
            <template v-if="data.item.betdata && (data.item.provider == 'DG')">
              <div :class="data.item.betdata.settle_amount - Math.abs(data.item.betdata.bet_amount) > 0 ? 'text-success':'text-danger'">{{ numberWithCommas(data.item.betdata.settle_amount - Math.abs(data.item.betdata.bet_amount)) }}</div>
            </template>
          </template>
          <template #cell(ip)="data">
            <template v-if="data.item.betdata && data.item.provider == 'SA'">
              {{ data.item.betdata.ip }}
            </template>
            <template v-if="data.item.betdata && data.item.provider == 'AE'">
              {{ data.item.betdata.payload.ip }}
            </template>
            <template v-if="data.item.betdata && data.item.betdata.detail && data.item.provider == 'SBO'">
              {{ data.item.betdata.detail.ip }}
            </template>
          </template>
        </b-table>
        <div>
          <b-pagination
            v-model="betDetailTable.page"
            :total-rows="betDetailTable.totalRows"
            :per-page="25"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card>
    </div>
    <b-modal
      v-model="modalResult.show"
      title=""
      header-bg-variant="dark"
      header-text-variant="dark"
      body-bg-variant="dark"
      body-text-variant="dark"
      footer-bg-variant="dark"
      footer-text-variant="dark"
      hide-footer
      hide-header
      centered
    >
      <iframe
        v-if="modalResult.url && modalResult.displayType =='iframe'"
        :src="modalResult.url"
        style="width: 500px; min-height: 500px;"
      />
      <img
        v-if="modalResult.url && modalResult.displayType =='image'"
        :src="modalResult.url"
        style="width: 500px; min-height: 500px;"
      />
    </b-modal>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {
  components: {
    BCard,
    BTable,
    BPagination,
  },
  directives: {
    Ripple,
  },
  props: {
    userInput: {
      type: String,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
    startdate: {
      type: String,
      default: moment().format('YYYY-MM-DD 00:00:00'),
    },
    enddate: {
      type: String,
      default: moment().add(1, 'days').format('YYYY-MM-DD 00:00:00'),
    },
  },
  data() {
    return {
      isBusy: false,
      pageOptions: [100, 200, 500, 1000],
      betDetailTable: {
        page: 1,
        page_size: 25,
        items: [],
        details: [],
        totalRows: 0,
        fields: [
          { key: 'bet_time', label: 'เวลาเดิมพัน', formatter: val => moment(val).format('DD/MM/YYYY HH:mm:ss') },
          { key: 'payout_time', label: 'เวลาเคลียร์', formatter: val => moment(val).format('DD/MM/YYYY HH:mm:ss') },
          { key: 'provider', label: 'ค่ายเกมส์' },
          { key: 'bet_id', label: 'เลขธุรกรรม' },
          { key: 'game_id', label: 'รอบ/เกมส์' },
          {
            key: 'bet_amount',
            label: 'เงินเดิมพัน',
            formatter: val => this.numberWithCommas(Math.abs(val) || '0'),
            tdClass: 'td-currency',
          },
          {
            key: 'result_amount',
            label: '+/-',
            formatter: (val, k, item) => this.numberWithCommas((item.result_amount) || 0),
            tdClass: val => (val > 0 ? 'text-success td-currency' : 'text-danger  td-currency'),
          },
          { key: 'betdetails', label: 'การเดิมพัน' },
          { key: 'result', label: 'ผล' },
          { key: 'ip', label: 'IP' },
        ],
      },
      tableQuery: {
      },
      modalResult: { show: false },
      /* eslint-disable global-require */
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    show() {
      if (this.show)
        this.fetchMemberBetDetail()
    },
    'betDetailTable.page': function (newValue) {
      console.log('betDetailTable.page::newValue', newValue)
      // @change="changePage($event, 'all')"
      this.changePage(newValue)
    },
  },
  mounted() {
    this.tableQuery.startdate = this.startdate
    this.tableQuery.enddate = this.enddate
    if (this.userInput !== null && this.userInput !== undefined) {
      this.fetchMemberBetDetail()
    }
  },
  methods: {
    viewResult(data, displayType) {
      this.modalResult.show = true
      this.modalResult.url = data
      this.modalResult.displayType = displayType
    },
    async fetchMemberBetDetail() {
      if (this.userInput === null || this.userInput === undefined)
        return

      let fieldName = 'phoneno'
      if (this.tableQuery.searchBy)
        delete this.tableQuery.searchBy
      if (/[a-zA-Z]/.test(this.userInput)) {
        fieldName = 'username'
        this.tableQuery.username = this.userInput
        this.tableQuery.searchBy = 'username'
        delete this.tableQuery.phoneno
      } else if (this.userInput !== '') {
        this.tableQuery.phoneno = this.userInput
        this.tableQuery.searchBy = 'phoneno'
        delete this.tableQuery.username
      }

      let query = ''
      if (this.tableQuery !== {}) {
        Object.keys(this.tableQuery).forEach((key, idx) => {
          if (this.tableQuery[key] === null) return
          if (this.tableQuery[key] === undefined) return
          if (idx > 0) query += '&'
          if ((this.tableQuery[key] || {}).value)
            query += `${key}=${encodeURIComponent(this.tableQuery[key].value)}`
          else
            query += `${key}=${encodeURIComponent(this.tableQuery[key])}`
        })
      }
      query += `&page=1&page_size=${this.betDetailTable.page_size}`
      await this.fetchBetDetails(query)
      // this.$refs.membertable.refresh()
    },
    async fetchBetDetails(query) {
      this.betDetailTable.details = []
      this.betDetailTable.items = []
      // this.betDetailTable.totalRows = 0
      try {
        const { data: { betDetails } } = await this.$http.get(`betdetails${query !== '' ? (`?${query}`) : ''}&game_provider=all`)
        // this.betDetailTable.page = betDetails.meta.current_page
        this.betDetailTable.items = betDetails.data
        this.betDetailTable.details = betDetails.betDetails
        this.betDetailTable.totalRows = betDetails.meta.total
      } catch (err) {
        this.betDetailTable.items = []
        this.betDetailTable.totalRows = 0
      }
      this.$forceUpdate()
    },
    async changePage(page, gp) {
      this.betDetailTable.page = page
      this.$set(this.betDetailTable, 'page', page)
      await this.$nextTick()
      this.$set(this.betDetailTable, 'page', page)
      this.betDetailTable.page = page
      this.$forceUpdate()
      if (this.userInput === null || this.userInput === undefined)
        return

      let fieldName = 'phoneno'
      if (this.tableQuery.searchBy)
        delete this.tableQuery.searchBy
      if (/[a-zA-Z]/.test(this.userInput)) {
        fieldName = 'username'
        this.tableQuery.username = this.userInput
        this.tableQuery.searchBy = 'username'
        delete this.tableQuery.phoneno
      } else if (this.userInput !== '') {
        this.tableQuery.phoneno = this.userInput
        this.tableQuery.searchBy = 'phoneno'
        delete this.tableQuery.username
      }

      let query = ''
      if (this.tableQuery !== {}) {
        Object.keys(this.tableQuery).forEach((key, idx) => {
          if (this.tableQuery[key] === null) return
          if (this.tableQuery[key] === undefined) return
          if (idx > 0) query += '&'
          if ((this.tableQuery[key] || {}).value)
            query += `${key}=${encodeURIComponent(this.tableQuery[key].value)}`
          else
            query += `${key}=${encodeURIComponent(this.tableQuery[key])}`
        })
      }
      query += `&page=${page}&page_size=${this.betDetailTable.page_size}`
      this.fetchBetDetails(query)
    },
    format_sa_bettype(data) {
      const betTypeMap = {
        0: 'Tie',
        1: 'Player',
        2: 'Banker',
        3: 'Player Pair',
        4: 'Banker Pair',
        54: 'LuckySix',
        25: 'NC. Tie',
        26: 'NC. Player',
        27: 'NC. Banker',
        28: 'NC. Player Pair',
        29: 'NC. Banker Pair',
        53: 'NC. LuckySix',
        30: 'SuperSix',
        36: 'Player Natural',
        37: 'Banker Natural',
        40: 'NC. Player Natural',
        41: 'NC. Banker Natural',
        42: 'Cow Cow Player',
        43: 'Cow Cow Banker',
        44: 'Cow Cow Tie',
      }
      let htmlResult = ''
      data.forEach(bet => {
        htmlResult += `แทง ${betTypeMap[bet.type]} จำนวน ${bet.amount} | `
      })
      return htmlResult
    },
    format_sexy_result(data, winner) {
      const cardSuit = {
        S: '♠',
        H: '♥',
        C: '♣',
        D: '♦',
      }
      const cardColor = {
        S: 'yellow',
        H: '#ea5455',
        C: 'yellow',
        D: '#ea5455',
      }
      const cardVal = {
        1: 'A',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
        10: '10',
        11: 'J',
        12: 'Q',
        13: 'K',
      }

      let html = `${winner}<br><br>Player Card:  `
      data.forEach((val, idx) => {
        if (idx < 3 && val !== '') {
          if (idx > 0)
            html += ' | '
          html += `<span style="color: ${cardColor[val[0]]};">${cardSuit[val[0]]}${cardVal[parseInt(val.substr(1, 3))]}</span>`
        }
      })
      html += '<br>Banker Card:  '
      data.forEach((val, idx) => {
        if (idx >= 3 && val !== '') {
          if (idx > 3)
            html += ' | '
          html += `<span style="color: ${cardColor[val[0]]};">${cardSuit[val[0]]}${cardVal[parseInt(val.substr(1, 3))]}</span>`
        }
      })
      return html
    },
    format_sa_betresult(data, kind) {
      const cardSuit = {
        1: '♠',
        2: '♥',
        3: '♣',
        4: '♦',
      }
      const cardVal = {
        1: 'A',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
        10: '10',
        11: 'J',
        12: 'Q',
        13: 'K',
      }
      const betTypeMap = {
        0: 'Tie',
        1: 'Player',
        2: 'Banker',
        3: 'Player Pair',
        4: 'Banker Pair',
        54: 'LuckySix',
        25: 'NC. Tie',
        26: 'NC. Player',
        27: 'NC. Banker',
        28: 'NC. Player Pair',
        29: 'NC. Banker Pair',
        53: 'NC. LuckySix',
        30: 'SuperSix',
        36: 'Player Natural',
        37: 'Banker Natural',
        40: 'NC. Player Natural',
        41: 'NC. Banker Natural',
        42: 'Cow Cow Player',
        43: 'Cow Cow Banker',
        44: 'Cow Cow Tie',
      }

      let htmlResult = ''
      const { payload } = data
      if (payload && kind === 'detail') {
        const players = []
        const bankers = []
        if (payload.GameResult.BaccaratResult.PlayerCard1) {
          players.push(`<span class="${payload.GameResult.BaccaratResult.PlayerCard1.Suit % 2 === 0 ? 'text-danger' : 'text-white'}">${cardSuit[payload.GameResult.BaccaratResult.PlayerCard1.Suit]}${cardVal[payload.GameResult.BaccaratResult.PlayerCard1.Rank]}</span>`)
        }
        if (payload.GameResult.BaccaratResult.PlayerCard2) {
          players.push(`<span class="${payload.GameResult.BaccaratResult.PlayerCard2.Suit % 2 === 0 ? 'text-danger' : 'text-white'}">${cardSuit[payload.GameResult.BaccaratResult.PlayerCard2.Suit]}${cardVal[payload.GameResult.BaccaratResult.PlayerCard2.Rank]}</span>`)
        }
        if (payload.GameResult.BaccaratResult.PlayerCard3) {
          players.push(`<span class="${payload.GameResult.BaccaratResult.PlayerCard3.Suit % 2 === 0 ? 'text-danger' : 'text-white'}">${cardSuit[payload.GameResult.BaccaratResult.PlayerCard3.Suit]}${cardVal[payload.GameResult.BaccaratResult.PlayerCard3.Rank]}</span>`)
        }

        if (payload.GameResult.BaccaratResult.BankerCard1) {
          bankers.push(`<span class="${payload.GameResult.BaccaratResult.BankerCard1.Suit % 2 === 0 ? 'text-danger' : 'text-white'}">${cardSuit[payload.GameResult.BaccaratResult.BankerCard1.Suit]}${cardVal[payload.GameResult.BaccaratResult.BankerCard1.Rank]}</span>`)
        }
        if (payload.GameResult.BaccaratResult.BankerCard2) {
          bankers.push(`<span class="${payload.GameResult.BaccaratResult.BankerCard2.Suit % 2 === 0 ? 'text-danger' : 'text-white'}">${cardSuit[payload.GameResult.BaccaratResult.BankerCard2.Suit]}${cardVal[payload.GameResult.BaccaratResult.BankerCard2.Rank]}</span>`)
        }
        if (payload.GameResult.BaccaratResult.BankerCard3) {
          bankers.push(`<span class="${payload.GameResult.BaccaratResult.BankerCard3.Suit % 2 === 0 ? 'text-danger' : 'text-white'}">${cardSuit[payload.GameResult.BaccaratResult.BankerCard3.Suit]}${cardVal[payload.GameResult.BaccaratResult.BankerCard3.Rank]}</span>`)
        }
        htmlResult = `<span class="${({ WIN: 'text-success', LOSE: 'text-danger', DRAW: 'text-primary' })[data.bet_result]}">${({ WIN: 'ชนะ', LOST: 'แพ้', DRAW: 'เสมอ' })[data.bet_result]}</span>`
        htmlResult += `<br/>Player : ${players.join(' | ')}<br/> Banker : ${bankers.join(' | ')}`
      } else if (kind === 'result') {
        htmlResult = `<span class="${({ WIN: 'text-success', LOSE: 'text-danger', DRAW: 'text-primary' })[data.bet_result]}">${({ WIN: 'ชนะ', LOST: 'แพ้', DRAW: 'เสมอ' })[data.bet_result]}</span>`
      }
      return htmlResult
    },
    format_sbo_betdetail(data, kind) {
      let htmlResult = ''
      const statusMap = {
        won: 'ชนะ',
        lose: 'แพ้',
        tie: 'เสมอ',
        draw: 'เสมอ',
        unknown: 'รอผล',
      }
      if (kind === 'subbet') {
        Object.values(data.subbet || []).forEach((sbo, idx) => {
          if (idx > 0)
            htmlResult += '<br><br>'
          htmlResult += `<div><span style="font-weight: 800;">แทง</span> ${sbo.betOption}</div>`
          htmlResult += `<div><span style="font-weight: 800;">แบบ</span> ${sbo.marketType}</div>`
          htmlResult += `<div><span style="font-weight: 800;">กีฬา</span> ${sbo.sportType}</div>`
          htmlResult += `<div><span style="font-weight: 800;">ต่อรอง</span> ${sbo.hdp}</div>`
          htmlResult += `<div><span style="font-weight: 800;">ค่าน้ำ</span> ${sbo.odds}</div>`
          htmlResult += `<div><span style="font-weight: 800;">คู่</span> ${sbo.match}</div>`
          htmlResult += `<div><span style="font-weight: 800;">ลีค</span> ${sbo.league}</div>`
          htmlResult += `<div><span style="font-weight: 800;">ครึ่งแรก</span> ${sbo.htScore}</div>`
          htmlResult += `<div><span style="font-weight: 800;">ครึ่งหลัง</span> ${sbo.ftScore}</div>`
          htmlResult += `<div><span style="font-weight: 800;">ผล</span> ${statusMap[sbo.status]}</div>`
        })
      } else if (kind === 'detail') {
        const detail = data.detail || {}
        const statusColorMap = {
          won: 'lime',
          lose: 'red',
          tie: 'white',
          draw: 'white',
          unknown: 'white',
        }
        htmlResult += `<div><span style="font-weight: 800;">ผลบิล</span> <span style="color: ${statusColorMap[detail.status]};">${statusMap[detail.status || 'unknown']}</span></div>`
        htmlResult += `<div><span style="font-weight: 800;">จำนวน</span> ${this.numberWithCommas(detail.winLost || 0)} บาท</div>`
        // htmlResult += `<div><span style="font-weight: 800;">กีฬา</span> ${detail.sportType}</div>`
        // htmlResult += `<div><span style="font-weight: 800;">ต่อรอง</span> ${detail.hdp}</div>`
        // htmlResult += `<div><span style="font-weight: 800;">ค่าน้ำ</span> ${sbo.odds}</div>`
        // htmlResult += `<div><span style="font-weight: 800;">คู่</span> ${sbo.match}</div>`
        // htmlResult += `<div><span style="font-weight: 800;">ลีค</span> ${sbo.league}</div>`
        // htmlResult += `<div><span style="font-weight: 800;">ครึ่งแรก</span> ${sbo.htScore}</div>`
        // htmlResult += `<div><span style="font-weight: 800;">ครึ่งหลัง</span> ${sbo.ftScore}</div>`
        // htmlResult += `<div><span style="font-weight: 800;">ผล</span> ${sbo.status}</div>`
      }
      return htmlResult
    },
    clearSearch() {
      this.walletTxn = []
      const ctx = this
      this.betDetailTable.items = []
      this.betDetailTable.totalRows = []
      this.betDetailTable.page = 1
      this.betDetailTable.details = []
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length
      // this.betDetailTable.totalRows
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.td-currency {
  text-align: right;
}
</style>
